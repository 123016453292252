import React from 'react'

import Logo from '../../Assets/ustac-logo-with-text.png'
import FacebookIcon from '@mui/icons-material/Facebook';
import LanguageIcon from '@mui/icons-material/Language';

export default function Footer() {
    return (
        <div className='w-full bg-lightGray py-4 text-gray px-10 text-sm'>
            <div className='max-w-screen-lg mx-auto'>
                <div className='flex flex-col md:flex-row justify-between'>
                    <div className='flex flex-col justify-center items-center md:items-start mt-6'>
                        <img src={Logo} alt="logo" className='w-[12rem]' />
                        <p className='mt-6'>Email: registrar.ustac@gmail.com</p>
                        <p>Tel: (053) 520-2358</p>
                    </div>
                    <div className='flex flex-col mt-6 items-center md:items-start gap-2'>
                        <p className='text-xl font-bold'>Links</p>
                        <a href="/">Home</a>
                        <a href="/about">About</a>
                        <a href="/?scrollTo=services">Services</a>
                        {/* <a href="">Requirements</a> */}
                    </div>
                    <div className='flex flex-col items-center mt-6  md:items-start'>
                        <p className='text-xl font-bold'>Social Media</p>
                        <div className='flex flex-col'>
                            <a href="https://www.facebook.com/ustac.inc"><FacebookIcon fontSize='large' />Universal Skills Training and Assessment Center Inc. </a>
                            <a href=""><LanguageIcon fontSize='large' />USTAC Website</a>
                        </div>
                    </div>
                </div>
                <div className='w-full flex gap-8 mt-8 justify-between'>
                    <p>Powered by MYT SOFTDEV SOLUTIONS</p>
                    <p>©2024 USTAC | All Rights Reserved.</p>
                </div>
            </div>
        </div>
    )
}
