import React from 'react'
import { Helmet } from 'react-helmet';

import AboutUs from '../../Assets/Images/about-us.png'
import Vision from '../../Assets/Images/vision.png'
import Mission from '../../Assets/Images/mission.png'
import Values from '../../Assets/Images/value.png'
import Mary from '../../Assets/Images/mary.png'
import Stephanie from '../../Assets/Images/stephanie.png'
import Darmae from '../../Assets/Images/darmae.png'

export default function About() {
  return (
    <div className='text-gray mx-auto tracking-wide'>
      <div className='flex w-full flex-col lg:flex-row h-full  max-w-screen-2xl mx-auto'>
        <div className='w-full flex flex-col p-16 justify-center'>
          {/* <p className='text-orange font-bold'>ABOUT US</p> */}
          <p className='text-blue mb-5 font-bold text-3xl'>Who We Are</p>
          <p className='text-justify'>Universal Skills Training and Assessment Center Inc. (USTAC) emerged on the educational landscape of Ormoc City in 2023, carving a niche as a specialized institution offering industryrequired skills training in ICT courses such as Computer Systems Servicing and Contact Center Services among others. This strategic focus reflects a commitment to delivering targeted and high-quality training, setting the stage for individuals to build strong foundations in these sought after fields. <br /><br />
            USTAC's mission revolves around a steadfast dedication to career development and skills enhacement to empower individuals towards excellence and workforce success. <br /><br />
            In essence, USTAC's approach extends beyond mere education; it embraces a holistic commitment to community development. By focusing on the out-of-school youth and unemployed individuals in Ormoc City, the center plays a crucial role in bridging the gap between aspiration and achievement, shaping not only the professional trajectories of its students but also contributing to the overall growth and resilience of the community it serves.
          </p>
        </div>
        <img src={AboutUs} alt="logo" className='w-full object-cover' />
      </div>

      <div className=' bg-blue w-screen'>
        <div className='max-w-screen-2xl mx-auto flex py-20 text-white gap-16 flex-col px-30 items-center '>
          <div className='w-3/4 flex items-center gap-10'>
            <img src={Vision} />
            <div>
              <p className='text-2xl font-bold mb-3'>VISION</p>
              <p>USTAC Inc. is a high-quality provider of TESDA-accredited training programs that secure better futures for its students.</p>
            </div>
          </div>
          <div className='w-3/4 flex items-start lg:items-center gap-10'>
            <img src={Mission} />
            <div>
              <p className='text-2xl font-bold mb-3'>MISSION</p>
              <p>We maintain a roster of affordable high quality programs with updated and complete facilities and equipment, eliminating financial barriers for individuals in need of training. We take care of our people's development retaining competent trainers and responsible staff. We assist graduates through counseling, bridging and placement to foster successful careers that contribute to a vibrant community.</p>
            </div>
          </div>
          <div className='w-3/4 flex items-center gap-10'>
            <img src={Values} />
            <div>
              <p className='text-2xl font-bold mb-3'>CORE VALUES</p>
              <p>Respect • Inclusivity • Excellence • Integrity • Collaboration • Community Contribution</p>
            </div>
          </div>
        </div>
      </div>

      <div className='flex flex-col justify-center'>
        <div className='bg-orange p-5 font-bold text-center text-2xl text-white'>Our Dedicated Team</div>
        <p className='p-10 text-justify max-w-screen-2xl mt-10 mx-auto'>Welcome to the heart of Universal Skills Training and Assessment Center Inc. (USTAC), where our commitment to excellence and passion for empowering individuals converge. Our team, is dedicated to being the preferred choice for career development. Join us as we empower individuals, shaping futures, and bridging the gap between aspirations and achievements. Welcome to USTAC – where skills meet success.</p>
        <div className='p-10 flex justify-center gap-12 mb-20 flex-col lg:flex-row gap-lg-48'>
          <div className='justify-center flex flex-col items-center'>
            <img src={Mary} alt="..." className='w-72 h-72 object-cover' />
            <p className='mt-3 font-bold text-lg'>Mary Jane Rivilla</p>
            <p>President</p>
          </div>
          <div className='justify-center flex flex-col items-center text-center'>
            <img src={Stephanie} alt="..." className='w-72 h-72 object-cover' />
            <p className='mt-3 font-bold text-lg'>Stephanie Diane U. Chu</p>
            <p>Center Administrator</p>
          </div>
          <div className='justify-center flex flex-col items-center text-center'>
            <img src={Darmae} alt="..." className='w-72 h-72 object-cover' />
            <p className='mt-3 w-full font-bold text-lg'>Darmae Tan</p>
            <p>Curriculum and Training Consultant</p>
          </div>
        </div>
      </div>
    </div>
  )
}
