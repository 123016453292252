import './App.css';
import { BrowserRouter as Router, Route, Switch, Routes } from 'react-router-dom';

//components
import Footer from './Components/Footer/Footer'
import Messenger from './Components/FacebookMessenger/Messenger'

//pages
import Home from './Pages/Home/Home';

function App() {
  return (
    <div className='overflow-hidden'>
      <Router>
        <Routes>
          <Route path='/' element={<Home />} />
        </Routes>
        <Footer/>
      </Router>
      <Messenger/>
    </div>
  );
}

export default App;
