import React, { useState, useRef } from 'react';
import { Link } from 'react-scroll';

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import ImageBackground from '../../Assets/Images/school-pic.png'
import Logo from '../../Assets/Images/logo.png'
import ColorBackground from '../../Assets/Images/background.png'
import ComputerServicing from '../../Assets/Images/computer-systems-servicing.png'
import ContactService from '../../Assets/Images/contact-service.png'
import WebDesign from '../../Assets/Images/web-design.png'
import CyberSecurity from '../../Assets/Images/computer-servicing.png'
import InternetForSeniors from '../../Assets/Images/internet-for-seniors.jpg'
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import LogoWithText from '../../Assets/ustac-logo-with-text.png'

import About from '../About/About';

export default function Home() {
  const [nav, setNav] = useState(false);
  const [activeLink, setActiveLink] = useState("");

  const data = [
    {
      title: 'Computer Systems Servicing',
      text: "This course teaches students crucial computer troubleshooting and maintenance skills through theory and hands-on training, covering issue diagnosis, operating system installation, and computer network setup.",
      img: ComputerServicing,
      alt: "computer-hardware-servicing",
      duration: "280 hours"
    },
    {
      title: 'Web Design',
      text: 'This course teaches students essential web design skills, encompassing both theoretical knowledge and practical training in areas such as layout design, coding, and user experience.',
      img: WebDesign,
      alt: "web-design",
      duration: "5 Months"
    },
    {
      title: 'Internet for Seniors',
      text: 'This course focuses on empowering seniors with fundamental internet skills, covering topics like online navigation, communication, and basic internet safety.',
      img: InternetForSeniors,
      alt: "internet-for-seniors",
      duration: "2 Months"
    },
    {
      title: 'Intro to Cyber Security',
      text: 'This course offers thorough cybersecurity training, covering key concepts, threat detection, and defense strategies to equip students with vital skills for safeguarding digital systems and information.',
      img: CyberSecurity,
      alt: "intro-to-cyber-security",
      duration: "3 Months"
    },
    {
      title: 'Contact Center Services Course',
      text: 'This course sharpens key skills for success in contact centers, including customer service, communication, problem-solving, and telephone etiquette.',
      img: ContactService,
      alt: "contact-center-services",
      duration: "144 hours"
    }
  ]

  const settings = {
    mobileFirst: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1050,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 630,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
    ]
  };

  const links = [
    {
      id: 1,
      link: 'home',
      title: 'Home'
    },
    {
      id: 2,
      link: 'about',
      title: 'About Us'
    },
    {
      id: 3,
      link: 'services',
      title: 'Services'
    }
  ];

  const handleLinkClick = (link) => {
    setActiveLink(link);
    setNav(false); // Close navbar after clicking a link
  };

  return (
    <div className='text-gray tracking-wider mx-auto'>
      {/* NAV */}
      <nav className="flex justify-between bg-lightGray items-center px-3 w-full h-14 text-sm text-gray bg-paleOak fixed z-10 md:px-16">
        <div className='w-full'>
          <img src={LogoWithText} className='w-24 h-auto' />
        </div>
        <div className='hidden md:flex flex-row w-full justify-end gap-2'>
          {links.map(({ id, link, title }) => (
            <Link
              key={id}
              to={link}
              spy={true}
              smooth={true}
              duration={500}
              className={`w-24 text-center cursor-pointer hover:text-white px-3 py-2 hover:bg-blue hover:px-3 hover:py-2 hover ${link === activeLink ? 'text-white bg-blue px-3 py-2' : ''}`}
              onClick={() => handleLinkClick(link)}
            >
              {title}
            </Link>
          ))}
        </div>
        <div
          onClick={() => setNav((prevNav) => !prevNav)}
          className="cursor-pointer z-10 text-gray-500 md:hidden"
        >
          {nav ? <CloseIcon fontSize='small' className="text-earthyGreen" /> : <MenuIcon fontSize='small' className="text-earthyGreen" />}
        </div>
        {nav && (
          <ul className="flex flex-col bg-blue text-white absolute top-[3.5rem] left-0 w-full text-holisticGreen py-2">
            {links.map(({ id, link, title }) => (
              <li
                key={id}
                className="px-4 py-3">
                <a href={link} className='hover:text-orange'>{title}</a>
              </li>
            ))}
          </ul>
        )}
      </nav>
      {/* NAV */}
      <div id='home' className='flex flex-row mx-auto relative h-screen w-full'>
        <div className='relative w-full'>
          <img className='h-full w-full object-cover' src={ImageBackground} alt='...' />
          <div className='h-full w-full md:w-0 bg-black opacity-30 absolute top-0'></div>
        </div>
        <div className='w-full h-full items-middle absolute md:relative'>
          <div className='flex flex-col w-full h-full justify-center pl-4 sm:pl-9'>
            <p className="text-white md:text-orange mb-2 text-sm lg:text-xl font-bold tracking-widest">BEGIN YOUR JOURNEY</p>
            <p className='text-4xl md:text-[3rem] leading-tight text-white md:text-blue font-bold font-secondaryBlack tracking-wider'>Universal Skills Training And <br />Assessment Center</p>
            <p className='text-white md:text-gray italic md:text-lg font-semibold mt-2'>“Empowering Futures, Enriching Lives”</p>
            <button id='enroll-now' className='px-9 py-3 w-fit font-bold bg-orange md:bg-gray text-sm tracking-widest text-white rounded-lg mt-8'>Enroll Now</button>
          </div>
        </div>
      </div>

      <div className='bg-lightGray w-screen'>
        <div className=' flex flex-col lg:flex-row gap-3 p-6 lg:p-28 max-w-screen-2xl mx-auto'>
          <div className='w-full p-16 flex justify-center lg:p-3'>
            <img src={Logo} alt="..." className='object-cover' />
          </div>
          <div className='flex flex-col w-full items-middle justify-center'>
            <p className=' text-orange font-bold text-2xl mb-5'>WELCOME TO USTAC</p>
            <p>At USTAC, we offer a variety of training programs in Information and Communication Technologies (ICT). These programs are designed to provide individuals with essential skills and knowledge necessary to excel in these fields. Our aim is to ensure that participants develop a solid understanding of ICT concepts and techniques, empowering them to thrive in this ever-evolving industry. Whether it's mastering coding languages, enhancing cybersecurity skills, or exploring emerging technologies, our courses are tailored to equip individuals with the tools they need to succeed in the ICT sector.</p>
          </div>
        </div>

      </div>
      <div id="services" className='relative h-[45rem]'>
        <img src={ColorBackground} alt="" className='absolute w-screen h-full' />
        <div className='h-full absolute w-full'>
          <div className='mx-auto max-w-screen-2xl w-full h-full items-center mt-10'>
            <p className='text-2xl py-5 mb-5 font-bold w-full text-center text-white tracking-widest'>OFFERED SERVICES</p>
            <Slider {...settings} className='mx-5'>
              {data.map((d) => (
                <div id={d.key} className='bg-white rounded-md flex flex-col overflow-hidden'>
                  <div>
                    <img src={d.img} alt={d.alt} className='w-full h-56 object-cover' />
                  </div>
                  <div className='p-6'>
                    <p className='text-lg font-bold mb-2'>{d.title}</p>
                    <p className='mb-2 h-32'>{d.text}</p>
                    {/* <button className='rounded-md mt-3 bg-blue text-sm hover:bg-orange text-white px-5 py-3 w-[10rem] font-bold'>More Details</button> */}
                    <p className="text-blue font-bold">Duration: {d.duration}</p>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      <div id="about">
        <About />
      </div>
    </div>
  )
}
