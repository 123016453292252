import React from 'react'
import { FacebookProvider, CustomChat } from 'react-facebook'

var app_id = process.env.REACT_APP_APP_ID;
var page_id = process.env.REACT_APP_PAGE_ID;

const Messenger = () => {
    return (
        <FacebookProvider appId={app_id} chatSupport>
            <CustomChat pageId={page_id} minimized={true} />
        </FacebookProvider>
    )
}

export default Messenger
